<template>
    <div class="field field-date-picker" :class="{ 'is-open': isOpen, 'is-invalid': errorMessage }">
        <label v-if="!formFloating && label" :for="idAttr" class="form-label">{{ label }}<span v-if="mandatory" class="text-danger ms-1">*</span></label>
        <Datepicker
            :id="idAttr"
            :disabled="disabled"
            :value="value"
            :placeholder="placeholder"
            :input-class-name="!!errorMessage ? 'form-control is-invalid' : 'form-control'"
            :autocomplete="autocomplete"
            @open="isOpen = true"
            @closed="isOpen = false"
            @update:model-value="emit('update:modelValue', value)"
            v-model="value"
            v-bind="{ 
                ...datePickerConfig, 
                textInput: true,
                menuClassName: `${datePickerConfig?.menuClassName} field-date-picker-menu`
            }">
            <template #dp-input="{ value, onInput, onEnter, onTab, onClear }">
                <div class="form-control-wrapper"
                    :class="{
                        'form-floating': formFloating,
                        'has-icon-start': $slots['icon-start'],
                    }">
                    <div v-if="$slots['icon-start']" class="icon-start">
                        <slot name="icon-start"></slot>
                    </div>
                    <input
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': !!errorMessage }"
                        :value="value"
                        @input="onInput"
                        @keyup.enter="onEnter"
                        @keydown.tab="onTab"
                        :autocomplete="autocomplete"
                        :disabled="disabled"
                        :placeholder="placeholder">
                    <label v-if="formFloating && label" :for="idAttr" class="form-label">{{ label }}</label>
                </div>
            </template>
        </Datepicker>
        <p v-if="hint" class="hint-message caption text-muted mt-1">
            <slot name="hint-prefix"></slot>
            {{ hint }}
        </p>
        <p v-if="warning" class="mt-1 text-warning caption">
            <slot name="warning-slot"></slot>
        </p>
        <p v-if="errorMessage" class="error-message text-danger caption">{{ errorMessage }}</p>
    </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent, ref, toRef, PropType, computed } from "vue";
import { useField } from "vee-validate";
import { type VueDatePicker } from '@vuepic/vue-datepicker';
import kebabCase from "lodash/kebabCase";
import '@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss';
import { Icon } from '@/modules/core/components';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    id: String,
    name: { type: String, required: true },
    label: String,
    floating: Boolean,
    disabled: Boolean,
    autocomplete: String,
    mandatory: Boolean,
    hint: String,
    placeholder: {
        type: String,
        default: 'Select Date'
    },
    datePickerConfig: {
        type: Object as PropType<VueDatePicker>,
    },
    formFloating: Boolean,
    warning: Boolean
})

const idAttr = computed(() => props.id ?? 'datePicker-' + kebabCase(props.name));

const Datepicker = defineAsyncComponent(() => import('@vuepic/vue-datepicker'));

const isOpen = ref(false);

const {
    value,
    errorMessage
} = useField<Date>(toRef(props, 'name'))

</script>

<style lang="scss">

.field-date-picker {
    .dp__main {
        font-family: inherit;
    }

    .icon-start,
    .icon-end {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .icon-start { left: .75rem; }
    .icon-end { right: .75rem; }

    .form-control-wrapper.has-icon-start {
        .form-control {
            padding-inline-start: 2.5rem;
        }

        label {
            padding-inline-start: 2.75rem;
        }
        .icon-start,
        .icon-end {
            z-index: 3;
        }
    }

    &.is-open {
        .form-control {
            outline-color: $primary;
            box-shadow: $input-focus-box-shadow;
            &.is-invalid {
                outline-color: $danger;
            }
        }
    }
}
.field-date-picker-menu {
    // .dp__instance_calendar, 
    // .dp__preset_ranges, 
    // .dp__calendar_header_item, 
    // .dp__calendar,
    // .dp__overlay {
    //     font-family: var(--bs-font-sans-serif); 
    // }
    .dp__preset_ranges {
        width: 100px;
        @include media-breakpoint-up(md) {
            width: 120px;
        }
    }
}

</style>